import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { StoreFiltersService } from '../../services/storeFilters.service';
import { Bloqueos } from '../../interfaces/bloqueos.interface';


@Component({
  selector: 'app-short-booking-card',
  templateUrl: './short-booking-card.component.html',
  styleUrl: './short-booking-card.component.css',
})
export class ShortBookingCardComponent implements OnInit{
  @Input() oferta: number = 0;
  @Input() nightPrice: number = 1;
  @Input() checkin: string | null = '';
  @Input() checkout: string | null = '';
  @Input()
  filterChange!: (nights: number) => void;
  @Input() fechasBloqueo: Bloqueos[] = [];

  @Input() mascotas: boolean = false;

  fromDate: any;
  toDate: any;
  cantidadNoches: number = 1;

  constructor(
    private storeFilters: StoreFiltersService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
	this.mascotas 
  }

  onDateRangeChanged() {
    if (this.storeFilters.getFilter().fromDate !== this.fromDate) {
      this.fromDate = this.storeFilters.getFilter().fromDate;
    }

    if (this.toDate !== this.storeFilters.getFilter().toDate) {
      this.toDate = this.storeFilters.getFilter().toDate;
    }
    this.getCantidadNoches();
    // this.calcularPrecioTotal();
    this._cdr.detectChanges();
  }

  getCantidadNoches() {
    // Convertir las cadenas de texto a objetos Date
    let checkIn = new Date(this.fromDate);
    let checkOut = new Date(this.toDate);

    // Calcular la diferencia de tiempo en milisegundos
    const diffTime = Math.abs(checkOut.getTime() - checkIn.getTime());
    if (this.cantidadNoches != Math.ceil(diffTime / (1000 * 60 * 60 * 24))) {
      this.filterChange(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
    }

    // Convertir la diferencia de tiempo en días y redondear hacia arriba
    this.cantidadNoches = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

}
