import { ChangeDetectorRef, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, catchError, switchMap, take } from "rxjs";
import { AlojamientosRepository } from "../repositories/alojamientos-post.repository";
import { AlojamientosPostUseCase } from "../usecase/alojamientos-post-use-case.service";
import { StoreFiltersService } from "../../shared/services/storeFilters.service";

@Injectable({
  providedIn: 'root'
})
export class DataFetchingService {

  private _postsMap = new BehaviorSubject<any[]>([]);
  private _posts = new BehaviorSubject<any[]>([]);
  postsMap$ = this._postsMap.asObservable();
  posts$ = this._posts.asObservable();
  private cargado = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _alojamientosPostService: AlojamientosPostUseCase,
    private _storeFilters: StoreFiltersService,
  ) { }

  fetchDataNotPaginatorCoordenadas() {
    this.cargado = true;
    const filters = this._storeFilters.getAnyFilter();
    filters['take'] = 50;
    delete filters['page'];
    this._posts.next([]);
    this._alojamientosPostService.getAlojamientosCoordenadas(filters).pipe(
      catchError((error) => {
        console.error('Error fetching data:', error);
        this.cargado = false;
        return [];
      })
    ).subscribe((data) => {
      let datos = data && data[0] ? data[0] : { alojamientos: [] };
      this._postsMap.next(datos.alojamientos);
      this.cargado = false;
    });
  }

  fetchDataNotPaginator() {
    this.cargado = true;
    const filters = this._storeFilters.getAnyFilter();
    delete filters['take'];
    delete filters['page'];
    this._posts.next([]);
    this._alojamientosPostService.getAlojamientosConFiltros(filters).pipe(
      catchError((error) => {
        console.error('Error fetching data:', error);
        this.cargado = false;
        return [];
      })
    ).subscribe((data) => {
      let datos = data[0];
      this._posts.next(datos.alojamientos);
      this.cargado = false;
    });
  }
}