<div (click)="goToDetails()" class="container">
    <app-card-reservation-state class="stateContainerCard" [state]="card.estado" [location]="card.propiedad[0].direccion" [fromDate]="card.from" [toDate]="card.to"></app-card-reservation-state>

        <div class="subContainer">
            <div class="card">
                <aside>
                    <img *ngIf="card.imagenes.length >0 " src={{card.imagenes[0].url.sm}} alt="" />
                    <img *ngIf="card.imagenes.length === 0" class="imagenCard" alt="" />
        
                    <div>
                        <div *ngIf="card.propiedad[0].permiteMascotas" class="petFriendly-tag">
							<div>
								<div class="flex gap-1">
									<p>Pet</p>
									<img src="../../../../assets/icons/pets.svg" />
								</div>
								<p>Friendly</p>
							</div>
						</div>
        
                        <div (click)="markAsFavorite($event)" class="image-overlay">
                            <img *ngIf="isFavorite" src="../../../../assets/icons/favorite-post-like.svg" />
                            <img *ngIf="!isFavorite" src="../../../../assets/icons/favorite-post.svg" />
        
                        </div>
                    </div>
                </aside>
        
            </div>
            <div class="ml-4">
                <h4 *ngIf="card.propiedad[0].tipoPropiedad !== 'BedBreakfast'">{{accommodationType}}</h4>
                <h4 *ngIf="card.propiedad[0].tipoPropiedad == 'BedBreakfast'">{{accommodationType}} {{card.nombre}}</h4>
                <div class="accomodationTypeContainer">
                    <p *ngIf="card.propiedad[0].tipoPropiedad == 'BedBreakfast'" class="bbType">{{card.propiedad[0].tipoPropiedad}}
                        {{card.propiedad[0].nombre}}</p>
                </div>
              

                <div class="mt-6">
                    <div *ngIf="card.propiedad[0].tipoPropiedad !== 'BedBreakfast'" class="detailsRoom-container">
                        <div *ngIf="card.cantidadCamas>=0" class="flex items-start">
                            <img src="../../../../assets/icons/bedroom.svg" alt="" />
                            <p *ngIf="card.cantidadCamas<=1">{{card.cantidadCamas}} {{'card.labelBedroom'|translate}}</p>
                            <p *ngIf="card.cantidadCamas>1">{{card.cantidadCamas}} {{'card.labelBedrooms'|translate}}</p>
                        </div>
                        <div *ngIf="card.cantidadBanos>=0" class="flex items-start">
                            <img src="../../../../assets/icons/shower.svg" alt="" />
                            <p *ngIf="card.cantidadBanos<=1">{{card.cantidadBanos}} {{'card.labelBathroom'|translate}}</p>
                            <p *ngIf="card.cantidadBanos>1">{{card.cantidadBanos}} {{'card.labelBathrooms'|translate}}</p>
                        </div>
                    </div>
                    <!-- Tipos a mostrar para bed and breakfast -->
                    <div *ngIf="card.propiedad[0].tipoPropiedad==='BedBreakfast'" class="detailsRoom-container">
                        <div class="flex items-start" *ngIf="card.cantidadCamas>=1">
                            <img src="../../../../assets/icons/bedroom.svg" alt="" />
                            <p>{{card.cantidadCamas}} {{bedType|translate}}</p>
                        </div>
                        <div class="flex items-start" *ngIf="card.cantidadCamas===0">
                            <img src="../../../../assets/icons/bedroom.svg" alt="" />
                            <p>{{card.cantidadCamas}} {{'card.labelBed'|translate}}</p>
                        </div>
    
    
                        <div class="flex items-start" *ngIf="card.cantidadBanos>=1">
                            <img src="../../../../assets/icons/shower.svg" alt="" />
                            <p>{{card.cantidadBanos}} {{bathroomType|translate}}</p>
                        </div>
                        <div class="flex items-start" *ngIf="card.cantidadBanos===0">
                            <img src="../../../../assets/icons/shower.svg" alt="" />
                            <p>{{card.cantidadBanos}} {{'card.labelBathroom'|translate}}</p>
                        </div>
                    </div>
                </div>

                <div class="location-container">
                    <img class="img" src="../../../../assets/icons/location-solid.svg" alt="" />
                    <h2 class="location">{{card.propiedad[0].direccion}}</h2>
                </div>
                <div>
                    <p>{{'card.textDistance1'|translate}} {{card.propiedad[0].distanciaAlCentro}} km
                        {{'card.textDistance2'|translate}}</p>
                </div>
            </div>
          
        </div>

   

</div>