<div class="bookingCardContainer">
    <div class="price">
        <h1 class="nightPriceCard"> {{nightPrice | currency: 'USD':'symbol':'1.2-2'}} </h1>
        <!-- <h1 class="nightPriceCard" *ngIf="oferta>0"> {{precioNocheOferta | currency: 'USD':'symbol':'1.2-2'}} </h1> -->
        <h4>{{'card.labelFor'|translate}} {{'card.labelNight'|translate}}</h4>
    </div>
    <app-ln-date-picker [cardBooking]="'datePicker'" (dateRangeSelected)="onDateRangeChanged($event)"
        [fechasBloqueo]="fechasBloqueo"></app-ln-date-picker>
    <app-ln-guest-selector [adults]="Adults" [pets]="Pets" [infants]="Infants" [children]="Children"
        (adultsChange)="handleAdultsChange($event)" (petsChange)="handlePetsChange($event)"
        (infantsChange)="handleInfantsChange($event)" (childrenChange)="handleChildrenChange($event)"
        [maxGuests]="maxGuests" [customClass]="'cardCustom'" [mascotas]="mascotas"></app-ln-guest-selector>
    <button (click)="gotToReservation()" class="buttonBookingCard">{{'card.textBooking'|translate}}</button>

    <div class="prices">
        <span>
            <!-- <h4 *ngIf="oferta>0"> {{precioNocheOferta | currency: 'USD':'symbol':'1.2-2'}} x {{cantidadNoches}} {{'searchBar.textNights'|translate}}</h4> -->
            <h4 class="priceText">{{nightPrice | currency: 'USD':'symbol':'1.2-2'}} x {{cantidadNoches}}
                {{'searchBar.textNights'|translate}}</h4>
        </span>
        <h4 class="symbol">$</h4>
        <h4 class="price">{{precioTotalNoches | number : '1.2-2'}}</h4>
        <h4 class="priceText">{{'card.textTarifaLimpieza'|translate}} </h4>
        <h4 class="symbol">$</h4>
        <h4 class="price">{{tarifaLimpieza | number : '1.2-2'}}</h4>
        <span>
            <span class=" flex tarifaLunaNueva" (mouseenter)="showAlert = true" (mouseleave)="showAlert = false">
                <h4 class="grid-item priceText">{{'card.textTarifaLunaNueva'|translate}}</h4>
                <img class="grid-item mobiletooltip" src="../../../../../assets/icons/field-alert.svg">
                <div [ngClass]="esp ? 'spanish-tooltip' : 'english-tooltip'">
                    <div *ngIf="showAlert" class=" ml-4 tooltip border border-gray-200 rounded-md shadow-md z-100">
                        <!-- Mensaje de alerta -->
                        <p class="text-tooltip text-sm text-white">{{ 'card.textTooltip' | translate }}</p>
                        <u class="moreInfo text-white text-sm"
                            (click)="showHint()">{{'card.textMoreInfo'|translate}}</u>
                    </div>
                </div>

            </span>
        </span>
        <h4 class="symbol">$</h4>
        <h4 class="price">{{tarifaLunaNueva | number : '1.2-2'}}</h4>
    </div>

    <hr class="lineCard">

    <div class="total">
        <h4 class="priceText">{{'card.textTotalImpuestos'|translate}}</h4>
        <h4 class="symbol">$</h4>
        <h4 class="price" [ngClass]="{'totalLineThrough': totalConTodo > 0}"> {{total | number : '1.2-2'}}</h4>
    </div>

    <div class="totalConDescuento" *ngIf="totalConTodo>0">
        <h4 class="priceText">{{'card.textTotalImpuestosOferta'|translate}}</h4>
        <h4 class="symbol">$</h4>
        <h4 class="price"> {{totalConTodo | number : '1.2-2'}}</h4>
    </div>
</div>