import { Mapper } from "../../core/base/mapper";
import { PropertyPostModel } from "../../core/domain/property-post-model";
import { PropertyBaseModel } from "../../core/domain/property-base-model";
import { PropertyTypeModel } from "../../core/domain/property-type-model";
import { PostPictureModel } from "../../core/domain/post-picture-model";
import { PropertyExtendedEntity } from "../entity/property-extended-entity";
import { environment } from "../../../environments/environment";
import { PropertyPostDetailEntity } from "../entity/poperty-detailed-post-entity";
import { PropertyDetailPostModel } from "../../core/domain/property-detail-post-model";
import { ImagenesModel } from "../../core/domain";

export class PropertyExtendedPosRepositoryMapper implements Mapper<PropertyPostDetailEntity, PropertyDetailPostModel> {
    mapFrom(param: PropertyPostDetailEntity): PropertyDetailPostModel {
        const pics: ImagenesModel[] = [];
        if (param.imagenes.length > 0 && param.imagenes) {
          param.imagenes.forEach(elm => {
            const urlParts = elm.url.toString().split('/');
            const fileName = urlParts.pop();
            const fileBase = urlParts.join('/');
            pics.push(<ImagenesModel>{
              idImagen: elm.idImagen,
              posicion: elm.posicion,
              url: {
                sm: environment.firebaseConfig.storageBucket + "/" + fileBase + "/sm/" + fileName,
                md: environment.firebaseConfig.storageBucket + "/" + fileBase + "/md/" + fileName,
                lg: environment.firebaseConfig.storageBucket + "/" + fileBase + "/lg/" + fileName
              }
            })
          });
        }
        return {
            idAlojamiento: param.idAlojamiento,
            idPropiedad: param.idPropiedad,
            codTipoAlojamiento: param.codTipoAlojamiento,
            esCompleto: param.esCompleto,
            descripcion: param.descripcion,
            descripcion_eng: param.descripcion_eng,
            createdAt: param.createdAt,
            updatedAt: param.updatedAt,
            areaTotal: param.areaTotal,
            nombre: param.nombre,
            nombre_eng:param.nombre_eng,
            maximoHuespedes: param.maximoHuespedes,
            tarifaLimpieza: param.tarifaLimpieza,
            cantidadBanos: param.cantidadBanos,
            cantidadCamas: param.cantidadCamas,
            cantidadHuespedes: param.cantidadHuespedes,
            tour360: param.tour360,
            alojamientos_tiposServicio:param.alojamientos_tiposServicio,
            tarifas: param.tarifas.map(tarifa => ({
                precioNoche: tarifa.precioNoche,
            })),
            ofertas: param.ofertas.map(oferta => ({
                porcentajeDescuento: oferta.porcentajeDescuento,
                fechaDesde:oferta.fechaDesde,
                fechaHasta:oferta.fechaHasta
            })),
            imagenes:pics,
            favoritos: param.favoritos,
            favorito:param.favorito,
            propiedades: {
                direccion: param.propiedades.direccion,
                ciudad: {
                    nombre: param.propiedades.ciudad.nombre
                },
                latitud: param.propiedades.latitud,
                longitud: param.propiedades.longitud,
                tipoPropiedad: {
                    descripcion: param.propiedades.tipoPropiedad.descripcion
                },
                distanciaAlCentro: param.propiedades.distanciaAlCentro,
                permiteMascotas: param.propiedades.permiteMascotas,
                informacionZona: param.propiedades.informacionZona,
                informacionZona_eng: param.propiedades.informacionZona_eng,
                checkIn: param.propiedades.checkIn,
                checkOut: param.propiedades.checkOut,
                smokeArea: param.propiedades.smokeArea,
                party: param.propiedades.party,
                loudMusic: param.propiedades.loudMusic,
                addicional: param.propiedades.addicional,
                ascensor: param.propiedades.ascensor,
                orientacion: param.propiedades.orientacion,
                desayuno: param.propiedades.desayuno,
                propiedades_politicas:param.propiedades.propiedades_politicas,
                nombre:param.propiedades.nombre,
                cantidadParking: param.propiedades.cantidadParking
            },
            ambientes: param.ambientes.map(ambiente => ({
                idAmbiente: ambiente.idAmbiente,
                codTipoAmbiente: ambiente.codTipoAmbiente,
                objetos: ambiente.objetos.map(objeto => ({
                    idObjeto: objeto.idObjeto,
                    catalogoObjetos: {
                        idCatalogoObjeto: objeto.catalogoObjetos.idCatalogoObjeto,
                        descripcion: objeto.catalogoObjetos.descripcion
                    }
                })),
                tiposAmbiente: {
                    descripcion: ambiente.tiposAmbiente.descripcion,
                    esCompartido:ambiente.tiposAmbiente.esCompartido,
                }
            })),
            bloqueos: param.bloqueos
            
        };
    }

    mapFromList(param: PropertyPostDetailEntity[]): PropertyDetailPostModel[] {
        const postModels: PropertyDetailPostModel[] = [];
        return postModels;
    }

    mapTo(param: PropertyDetailPostModel): PropertyPostDetailEntity {
        return <PropertyPostDetailEntity>{}
    }

    mapToList(param: PropertyDetailPostModel[]): PropertyPostDetailEntity[] {
        return [];
    }

}
