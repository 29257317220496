import { afterNextRender, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, inject, Injector, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

import { ContactFormUseCaseService } from "../../../core/usecase/contact-form-use-case.service";
import { ModalService } from "../../services/modal.service";
import { DialogService } from "../../services/dialog.service";
import { CaptchaService } from "../../services/captcha.service";
import { Location } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { SuccessfulContactFormComponent } from '../successful-contact-form/successful-contact-form.component';
import { Rubros } from '../../lists/rubros';
import { ServicesTypes } from '../../lists/services-types';
import { StoreFiltersService } from '../../services/storeFilters.service';
import { UserEditUseCaseService } from '../../../core/usecase/user-edir-use-case.service';
import { emailDomainValidator } from '../../helpers/helpers';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ReservartionCardComponent } from '../reservartion-card/reservartion-card.component';


export interface ContactForm {
  names: FormControl,
  phone: FormControl,
  email: FormControl,
  message: FormControl,
  message2: FormControl,
  services: FormControl,
  copy: FormControl
}

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css', './2contact-form.component.css'],
})
export class ContactFormComponent implements AfterViewInit{

  telephonePrefix: string = ''
  contactOptions: any[] = [{ key: 'contactUsForm.textOption1', value: '1' }, { key: 'contactUsForm.textOption2', value: '2' },{ key: 'contactUsForm.textOption3', value: '3' }];
  contadorCaracteresMessage: number = 0;
  contadorCaracteresRecomendacion: number = 0;
  servicesTypeList: any = [];
  rubros: any = [];

  @Input() title: string = 'Contáctanos';
  @Input() message: string = '';
  @Input() isProperty = false;
  @Input() card: any;
  @Input() totalNights: number = 1;
  @Output() contactData = new EventEmitter<Object>();
  @Input() isService = false;
  error = 'Campo requerido *';
  fromDate: any;
  toDate: any;
  guests:number=0;

  private _injector = inject(Injector);

  @ViewChild('autosize') autosize: CdkTextareaAutosize | undefined;
  @ViewChild(ReservartionCardComponent) reservationCardComponent!: ReservartionCardComponent;

  formGroup: FormGroup<ContactForm> = new FormGroup(
    {
      names: new FormControl('', [Validators.required,Validators.pattern("^[A-Za-zÀ-ÿ\\s]+$")]),
      phone: new FormControl('', [Validators.required, Validators.pattern("^[0-9]+$"),]),
      message: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      message2: new FormControl('', [Validators.maxLength(250)]),
      services: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email,emailDomainValidator('yahoo.com')]),
      copy: new FormControl(false),
    }
  );


  constructor(
    private _captchaService: CaptchaService,
    private _contactFormUseCaseService: UserEditUseCaseService,
    private _modal: ModalService,
    private _dialogService: DialogService,
    private _location: Location,
    private cdr: ChangeDetectorRef,
    private _storeFilters: StoreFiltersService,

  ) {
    this.rubros = new Rubros().rubros.map(el => ({ key: el.name, value: el.es + "," + el.en }));
    this.servicesTypeList = new ServicesTypes().services.map((el) => ({ key: el.name, value: el.es + "," + el.en }));
  }

  triggerResize() {
    afterNextRender(
      () => {
        this.autosize!.resizeToFitContent(true);
      },
      {
        injector: this._injector,
      },
    );
  }

  async ngOnInit() {
     this.formGroup.controls.message.setValue(this.message);
     this.contadorCaracteresMessage=this.message.length;
    this.formGroup.controls.message.valueChanges.subscribe(value => {
      this.contadorCaracteresMessage = value.length;
    });
    this.formGroup.controls.message2.valueChanges.subscribe(value => {
      this.contadorCaracteresRecomendacion = value.length;
    });

    if(this.isProperty){
      const servicesControl = this.formGroup.get('services')!;
      servicesControl.clearValidators();
      servicesControl.updateValueAndValidity();
    }
    this.getGuests();
    this.getDates();

  }

  getGuests(){

    let children=0;
    let adults=0;

    if (this._storeFilters.getFilter().Children) {
      children = parseInt(this._storeFilters.getFilter().Children);
    }
    if (this._storeFilters.getFilter().Adults) {
      adults = parseInt(this._storeFilters.getFilter().Adults);
    }
    this.guests = adults+children;
  
    this.cdr.detectChanges();
  }

  getDates(){
    this.fromDate = this._storeFilters.getFilter().fromDate;
    this.toDate = this._storeFilters.getFilter().toDate;

    if(this.fromDate && this.toDate){
      this.fromDate = this.convertStringToDate(this.fromDate);
      this.toDate = this.convertStringToDate(this.toDate);
      this.fromDate=this.formatDate(this.fromDate);
      this.toDate=this.formatDate(this.toDate);
    //  console.log('A: ',this.fromDate, 'B: ', this.toDate, 'C: ',checkIn,  'D: ',checkOut);
    }
    this.cdr.detectChanges();

  }

  convertStringToDate(fechaString: string): Date {
    const [year, month, day] = fechaString.split('-').map(Number);
    return new Date(year, month - 1, day); 
  }

  formatDate(fecha: Date): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return fecha.toLocaleDateString('en-US', options);
  }
  

  ngAfterViewInit() {
    this.autosize!.resizeToFitContent(true);
  }


  async sendEmails() {


    if (this.formGroup.invalid) {
      this.markAllAsTouched(this.formGroup);
    }
     else {

      const captcha: string = await this._captchaService.getCaptchaToken()

      if (!this.formGroup.controls.message.value)
        this.formGroup.controls.message.setValue(this.message);

      this._contactFormUseCaseService.currentTelephonePrefix.subscribe((prefix) => {

        this.telephonePrefix = prefix;
        this.formGroup.value.phone = `+${this.telephonePrefix}-${this.formGroup.value.phone}`;

        if(this.isService){
          this.contactData.emit({
            ...this.formGroup.value,
            captcha,
          });

        } else {
          this.contactData.emit({
            ...this.formGroup.value,
            captcha,
            accomodationType: this.reservationCardComponent.getValoresCard().accomodationType,
            cantidadNoches: this.reservationCardComponent.getValoresCard().cantidadNoches,
            precioPorNoche: this.reservationCardComponent.getValoresCard().precioPorNoche,
            precioTotalNoches: this.reservationCardComponent.getValoresCard().precioTotalNoches,
            tarifaLimpieza: this.reservationCardComponent.getValoresCard().tarifaLimpieza,
            tarifaLunaNueva: this.reservationCardComponent.getValoresCard().tarifaLunaNueva,
            total:this.reservationCardComponent.getValoresCard().total,
            cantHuespedes:this.guests.toString(),
            fromDate:this.fromDate,
            toDate:this.toDate,
            discount:this.reservationCardComponent.getValoresCard().totalConDescuento.toString(),
          });
        }

      });
     }



  }

  markAllAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);
      if (control) {
        control.markAsTouched();
        control.markAsDirty();
        control.updateValueAndValidity();
      }
      if(control && control.invalid){
        // console.log(`Field ${controlName} is invalid. Errors:`, control.errors);
      }

    });
    this.cdr.detectChanges();

  }

  checkBoxChanged(event: any) {
    this.formGroup.controls.copy.setValue(event.valueOf())
  }

  goBack() {
    this._location.back();
  }

  definePrice = () => {

  }

  private _matDialogPrice = inject(MatDialog);
  success() {
    this._matDialogPrice
      .open(SuccessfulContactFormComponent, {
        panelClass: 'app-reservation-price-changed',
        data: { newPrice: 20000, oldPrice: 22000 }
      })
  }
}



